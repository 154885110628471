import React from 'react'
import { graphql } from 'gatsby'
import Hero from '../components/global/Hero'
import IntroSectionReuseable from '../components/reuseable/IntroSectionReuseable'
import GalleryReuseable from '../components/reuseable/GalleryReuseable'
import CTASection from '../components/global/CTASection'
import Texture from '../components/animation/Texture'
import { Helmet } from 'react-helmet'

export default function OurShowroom(props) {
  
  const {gallery} = props.data.wpPage.galleryFields
  console.log(gallery)
  return (
    <div>
      <Helmet>
          <title>{props.data.wpPage.seo.title}</title>
          <meta name="description" content={props.data.wpPage.seo.metaDesc} />
          <meta name="og:description" content={props.data.wpPage.seo.metaDesc} />
          <meta property="og:title" content={props.data.wpPage.seo.title} />          
          <meta property="og:image" content={'https://morlandbathrooms.com'+props.data.wpPage.customHeroImage.heroImage.localFile.publicURL} />
      </Helmet>
      <div className="offpage-translate offpage-translate-bg"></div>
      <div className="offpage-translate">
        <Hero image={props.data.wpPage.customHeroImage.heroImage} title={props.data.wpPage.title}/>
        <IntroSectionReuseable defaultPageFields={props.data.wpPage.defaultPageFields} />
        <GalleryReuseable gallery={gallery} />
        <Texture 
        className='texture full' 
        opacity={0.05}  
        position='absolute'
        bottom="0"
        height={''}
        top="0"
        watchScroll={false}
        />
        <CTASection ctaType={'chat'} />
      </div>
    </div>
  )
}

export const query = graphql`
  query {
    wpPage(title: {eq: "Our showroom"}) {
      id
      title
      seo {
        title
        metaDesc
      }
      customHeroImage{
        heroImage{
          localFile{
            publicURL
          }
        }
      }
      defaultPageFields {
        contentTitle
        contentText
        contentImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 2000
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
      galleryFields {
        gallery {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1500
                  placeholder: NONE
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
      customHeroImage {
        heroImage {
          localFile{
            childImageSharp {
              gatsbyImageData(
                width: 1500
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`