import React from 'react'
import { Styling } from './styled.js'
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
import ContextConsumer from "../../../layouts/Context"

export default function GalleryReusable({gallery}) {
    return (
        <Styling>
            <div className="gallery-wrapper">
                {gallery.map((image, index) => {
                    const theImage = getImage(image.image.localFile);
                    return(
                        <ContextConsumer>
                            {({ data, set }) => (
                            <div 
                            className="image-wrapper" 
                            key={`gallery_${index}`} 
                            onClick={() => set({ 
                                menuOpen: !data.menuOpen, 
                                image: image.image.localFile,
                                imageIndex: index,
                                theGallery:gallery,
                            })}
                            >
                                <GatsbyImage image={theImage} className="image" alt={'test'} />
                            </div>
                            )}
                        </ContextConsumer>
                    )
                })}
            </div>
        </Styling>
    )
}
