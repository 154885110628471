import React from 'react'
import styled from 'styled-components'

const Styling = styled.div`
width:100%;
position: relative;
padding:0 2rem 4rem 2rem;
z-index: 100;

    @media only screen and (min-width: 450px) {
    padding:0 4rem 4rem 4rem;
    }
    @media only screen and (min-width: 1024px) {
        padding:0 10rem 8rem 10rem;
    }

    .gallery-wrapper{
    width: 100%;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(1, 1fr);

        @media only screen and (min-width: 600px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media only screen and (min-width: 1024px) {
            grid-template-columns: repeat(3, 1fr);
        }
    

        .image-wrapper{
        width: 100%;
        padding-bottom: 66%;
        position: relative;
        display: block;

            img{
            width: 100% !important;
            height:100% !important;
            position: absolute !important;
            top:0 !important;
            left:0 !important;
            }
        
        }

        .image{
        //border:1rem solid var(--white);
        background:var(--white);
        cursor: pointer;

            &.gatsby-image-wrapper{
            position: absolute;
            overflow: hidden;
            width: 100%;
            height: 100%;
            }
        }
    }
`

export { Styling }